import api from '../utils/api'

export default {
  get (chatId) {
    return api.get(`chats/${chatId}`)
  },
  getAll (params) {
    return api.get('chats', { params: params })
  },
  addMessage (chatId, data) {
    return api.patch(`chats/${chatId}`, data)
  }
}
