<template>
  <div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <h4 class="title">Сообщения от пациентов</h4>

          <spinner v-show="loading"></spinner>

          <table class="table" v-if="haveItems() && !loading">
            <thead>
            <tr>
              <th>Пациент</th>
              <th>PID</th>
              <th>Дата посл. сообщения</th>
              <th colspan="1"></th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(chat, index) in items">
              <td>{{ chat.patient_user.full_name }}</td>
              <td>{{ chat.medialog_patient_id }}</td>
              <td>{{ chat.updated_at | dateTime }}</td>

              <td class="is-icon">
                <router-link :to="{ name: 'Chat', params: { id: chat.uuid }}">
                  <i class="fa fa-edit"></i>
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>

          <pagination
                  @change="changePage"
                  :total-pages="pagination.total"
                  :limit="pagination.limit"
                  :page="pagination.current"
          ></pagination>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import chatsService from '../../services/chats'
import itemListViewMixin from '../../mixins/itemListView'

export default {
  mixins: [itemListViewMixin(chatsService, 'chats', false)],
  data () {
    return {
      requestParams: {
        'sort[updated_at]': 'desc'
      }
    }
  }
}
</script>
